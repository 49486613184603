<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('Store'),
            icon: 'ni ni-shop text-primary',
            path: '/store',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('Table'),
            icon: 'ni ni-settings text-primary',
            path: '/table',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('Staff'),
            icon: 'ni ni-single-02 text-primary',
            path: '/staff',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: $t('Queue'),
            icon: 'ni ni-user-run text-primary',
            path: '/queue',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: $t('Reserve'),
            icon: 'ni ni-map-big text-primary',
            path: '/reserve',
          }"
        ></sidebar-item>
        <!-- sidebar-item
          :link="{
            name: $t('Import / Export'),
            icon: 'ni ni-ungroup text-primary',
            path: '/import',
          }"
        >
        </sidebar-item -->

        <sidebar-item
          :link="{
            name: $t('Logout'),
            path: '/login',
            icon: 'ni ni-key-25 text-primary',
          }"
        >
        </sidebar-item>
      </template>

      <template slot="links-after">
        <hr class="my-3" />
        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item href="#">
            <b-nav-text class="p-0">Copyright © 2014-2018 POSKING</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- content-footer v-if="!$route.meta.hideFooter"></content-footer -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
  },
  data() {
    return {};
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
