var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Store'),
          icon: 'ni ni-shop text-primary',
          path: '/store',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Table'),
          icon: 'ni ni-settings text-primary',
          path: '/table',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Staff'),
          icon: 'ni ni-single-02 text-primary',
          path: '/staff',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Queue'),
          icon: 'ni ni-user-run text-primary',
          path: '/queue',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Reserve'),
          icon: 'ni ni-map-big text-primary',
          path: '/reserve',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Logout'),
          path: '/login',
          icon: 'ni ni-key-25 text-primary',
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"href":"#"}},[_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Copyright © 2014-2018 POSKING")])],1)],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }