import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";

import NotFound from "@/views/NotFoundPage.vue";
import Lineup from "@/views/Pages/Lineup.vue";
import Hold from "@/views/Pages/Hold.vue";
import Linemg from "@/views/Pages/Linemg.vue";
import Linetv from "@/views/Pages/Linetv.vue";
import Login from "@/views/Pages/Login.vue";

const routes = [
  {
    path: "/",
    redirect: "login",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Dashboard.vue")
      },
      {
        path: "/store",
        name: "store",
        component: () =>
          import("../views/Store/Store.vue")
      },
      {
        path: "/table",
        name: "table",
        component: () =>
          import("../views/Store/Table.vue")
      },
      {
        path: "/queue",
        name: "queue",
        component: () =>
          import("../views/Store/Queue.vue")
      },
      {
        path: "/reserve",
        name: "reserve",
        component: () =>
          import("../views/Store/Reserve.vue")
      },
      {
        path: "/staff",
        name: "staff",
        component: () =>
          import("../views/Store/Staff.vue")
      },
      {
        path: "/reservesetup",
        name: "reservesetup",
        component: () =>
          import("../views/Store/ReserveSetup.vue")
      },
      
      {
        path: "/commingsoon",
        name: "Comming Soon",
        component: () =>
          import("../views/Store/CommingSoon.vue")
      }
    ]
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/linemg",
    component: Linemg,
  },
  {
    path: "/linetv",
    component: Linetv,
  },
  {
    path: "/hold",
    component: Hold,
  },
  {
    path: "/lineup",
    component: Lineup,
  },
  {
    path: "/",
    redirect: "weblogin",
    component: AuthLayout,
    children: [
      {
        path: "/weblogin",
        name: "weblogin",
        component: () =>
          import("../views/Pages/WebLogin.vue")
      },
      { path: "*", component: NotFound }
    ]
  }
];

export default routes;
