var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"mainbody"},[_c('b-row',[_c('b-col',[_c('b-row',{ref:"titlerow",staticClass:"pt-2"},[_c('b-col',[_c('span',[_c('img',{staticClass:"logosize",attrs:{"src":"img/brand/logo.jpg"}})])]),_c('b-col',{staticClass:"text-right"},[(_vm.lang == 'en')?_c('b-button',{staticClass:"language",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.setLanguage('cn')}}},[_vm._v("中文")]):_vm._e(),(_vm.lang == 'cn')?_c('b-button',{staticClass:"language",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.setLanguage('en')}}},[_vm._v("English")]):_vm._e()],1)],1),_c('b-row',{ref:"bodyrow",staticClass:"justify-content-center",style:(_vm.bodyChangeHeight)},[_c('b-col',[_c('b-alert',{attrs:{"variant":"danger","fade":"","show":_vm.showDismissibleAlert,"dismissible":""},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_vm._v(" "+_vm._s(_vm.alert_msg)+" ")]),_c('div',{ref:"tabswidth"},[_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":_vm.$t('Queue'),"lazy":"","active":""}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.newlist,"stripe":"","fit":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"num","min-width":10,"label":_vm.$t('Number')}}),_c('el-table-column',{attrs:{"prop":"party","min-width":10,"label":_vm.$t('Party')}}),_c('el-table-column',{attrs:{"prop":"phone","min-width":25,"label":_vm.$t('Telephone')}}),_c('el-table-column',{attrs:{"prop":"name","min-width":25,"label":_vm.$t('Name')}}),_c('el-table-column',{attrs:{"min-width":10,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'call')}}},[_vm._v(" "+_vm._s(_vm.$t('Call'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":10,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":10,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'pass')}}},[_vm._v(" "+_vm._s(_vm.$t('Pass'))+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('Call'),"lazy":"","disabled":_vm.calllist.length==0}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.calllist,"stripe":"","fit":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"num","min-width":10,"label":_vm.$t('Number')}}),_c('el-table-column',{attrs:{"prop":"party","min-width":10,"label":_vm.$t('Party')}}),_c('el-table-column',{attrs:{"prop":"table_name","min-width":10,"label":_vm.$t('Table')}}),_c('el-table-column',{attrs:{"prop":"phone","min-width":20,"label":_vm.$t('Telephone')}}),_c('el-table-column',{attrs:{"prop":"name","min-width":20,"label":_vm.$t('Name')}}),_c('el-table-column',{attrs:{"min-width":15,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":15,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'done')}}},[_vm._v(" "+_vm._s(_vm.$t('Done'))+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('Pass'),"lazy":"","disabled":_vm.passlist.length==0}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.passlist,"stripe":"","fit":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"num","min-width":10,"label":_vm.$t('Number')}}),_c('el-table-column',{attrs:{"prop":"party","min-width":10,"label":_vm.$t('Party')}}),_c('el-table-column',{attrs:{"prop":"phone","min-width":25,"label":_vm.$t('Telephone')}}),_c('el-table-column',{attrs:{"prop":"name","min-width":25,"label":_vm.$t('Name')}}),_c('el-table-column',{attrs:{"min-width":15,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'call')}}},[_vm._v(" "+_vm._s(_vm.$t('Call'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":15,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_active(row, 'done')}}},[_vm._v(" "+_vm._s(_vm.$t('Done'))+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('Table'),"lazy":""}},[_c('div',{staticClass:"tablesdiv"},[_c('div',[_vm._l((_vm.floorlist),function(floor,idx){return _c('el-radio',{key:idx,attrs:{"label":idx},model:{value:(_vm.cur_floor_idx),callback:function ($$v) {_vm.cur_floor_idx=$$v},expression:"cur_floor_idx"}},[_vm._v(_vm._s(floor.name))])}),_c('el-button',{staticClass:"mr-3",staticStyle:{"float":"right"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.table_reset_color}},[_vm._v(_vm._s(_vm.$t('Reset')))])],2),_c('div',{ref:"tableImage",style:(_vm.manage_table_style)},_vm._l((_vm.tablelist),function(tb,idx){return _c('div',{key:idx,style:(_vm.table_style(tb)),on:{"click":function($event){return _vm.table_change_color(tb)}}},[_vm._v(_vm._s(tb.name))])}),0)])]),_c('b-tab',{attrs:{"title":_vm.$t('Reserve'),"lazy":""}},[_c('div',{staticClass:"mb-2"},_vm._l((_vm.weeklist),function(dt){return _c('el-button',{key:dt,attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.get_week_reservation(dt)}}},[_vm._v(" "+_vm._s(dt)+" ")])}),1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.reservelist,"stripe":"","fit":"","size":"mini"}},[_c('el-table-column',{attrs:{"prop":"tm","min-width":15,"label":_vm.$t('Time')}}),_c('el-table-column',{attrs:{"prop":"number","min-width":7,"label":_vm.$t('Party')}}),_c('el-table-column',{attrs:{"prop":"status","min-width":7,"label":_vm.$t('Status')}}),_c('el-table-column',{attrs:{"prop":"phone","min-width":25,"label":_vm.$t('Telephone')}}),_c('el-table-column',{attrs:{"prop":"name","min-width":25,"label":_vm.$t('Name')}}),_c('el-table-column',{attrs:{"min-width":10,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","disabled":row.status!='New',"size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_reserve_active(row, 'cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":10,"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","disabled":row.status!='New',"size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.make_reserve_active(row, 'done')}}},[_vm._v(" "+_vm._s(_vm.$t('Done'))+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }