<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row ref="titlerow">
        <b-col>
          <b-row class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        ref="bodyresult"
        v-if="rnumber"
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-card no-body class="bg-secondary border-0 mt-3">
            <b-card-body>
              <div class="text-center mb-4 done_store">
                {{ $t("Wecome to") }} {{ store.name }}
              </div>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Number") }}
                </b-col>
                <b-col>
                  {{ rnumber }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Time") }}
                </b-col>
                <b-col>
                  {{ rtm }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Party") }}
                </b-col>
                <b-col>
                  {{ number }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Contact") }}
                </b-col>
                <b-col>
                  {{ name }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Telephone") }}
                </b-col>
                <b-col>
                  {{ phone }}
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        ref="bodyrow"
        v-else
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-alert
            variant="danger"
            fade
            :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert=false"
            dismissible
          >
            {{ alert_msg }}
          </b-alert>
          <b-card no-body class="bg-secondary border-0 mt-3">
            <b-card-body v-if="store" class="px-lg-5 py-lg-5 text-center">
              <div class="text-center mb-2 done_store">
                {{ $t("Wecome to") }} {{ store.name }}
              </div>
              <b-form-group
                label-for="name"
                :label="$t('Name')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="name" v-model="name"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="phone"
                :label="$t('Telephone')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="phone" v-model="phone" type="tel"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="number"
                :label="$t('Party')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="number" v-model="number" type="number" @update="get_reserve_tables"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="slsdate"
                :label="$t('Date')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-select v-model="slsdate" :options="datelist_options" @change="reserve_date">
                  <template #first>
                    <b-form-select-option value="" disabled>{{ $t('Select Date') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                label-for="slstime"
                :label="$t('Time')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-select v-model="slstime" :options="timelist_options">
                  <template #first>
                    <b-form-select-option value="" disabled>{{ $t('Select Time') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                label-for="verify_code"
                :label="$t('Verify Code')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="verify_code" v-model="verifycode" type="text"></b-form-input>
              </b-form-group>
              <div class="mt-1 mb-3 text-right">
                <img :src="verifyimgdata" class="verifyimg" />
                <el-button type="primary" class="ml-1" icon="el-icon-refresh" circle @click="reload_image"></el-button>
              </div>
              <base-button
                class="btn btn-primary"
                @click="submit_form"
                >{{ $t("Apply") }}</base-button
              >
            </b-card-body>
            <div v-else class="text-center">
              {{ $t("Unknown Store") }}
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import {Button} from "element-ui";
import BaseButton from "../../components/BaseButton.vue";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Button.name]: Button,
    BaseButton,
    VueElementLoading,
  },
  data() {
    return {
      lang: "en",
      showDismissibleAlert: false,
      alert_msg: "test msg",
      errorModel: false,
      windowHeight: 100,
      titleHeigh: 100,
      bodyChangeHeight: { height: "500px" },

      store: false,
      showloading: false,

      recaptcha_token: "",

      name: "",
      phone: "",
      number: "",
      slsdate: "",
      slstime: "",

      rdata: [],
      datelist: [],
      timelist: [],
      datelist_options: [],
      timelist_options: [],

      rnumber: false,
      rtm: "",
      verifyimgdata: null,
      verifycode: '',

      user_id: 0,
      token: "",
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    this.lang = getStore("lang");
    if (this.lang != "cn") {
      this.lang = "en";
    } else {
      this.lang = "cn";
    }
    setStore("lang", this.lang);
    this.init();
  },
  methods: {
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
    },
    build_date_options() {
      this.datelist_options = [];
      for (var i = 0; i < this.datelist.length; i++) {
        this.datelist_options.push({value: this.datelist[i], text: this.datelist[i]});
      }
    },
    build_time_options() {
      this.timelist_options = [];
      if ((this.timelist == undefined) || !this.timelist || (Object.keys(this.timelist).length === 0)) {
        this.timelist_options.push({value: "", text: this.$t('No Options'), disabled: true});
      } else {
        for (var key in this.timelist) {
          this.timelist_options.push({value: this.timelist[key], text: key});
        }
      }
    },
    reserve_date() {
      if (this.rdata[this.slsdate] != undefined) {
        this.timelist = this.rdata[this.slsdate];
        this.build_time_options();
      } else {
        this.timelist = [];
      }
    },
    reload_image() {
      var bodyFormData = new FormData();
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/vimage",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.verifyimgdata = rt.data.verifyimg
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
    get_reserve_tables() {
      this.datelist_options = [];
      this.timelist_options = [];
      if (this.name.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Name is Require";
        return;
      }
      if (this.phone.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Phone Number is Require";
        return;
      }
      if (this.number.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Number of Party is Require";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("name", this.name);
      bodyFormData.append("phone", this.phone);
      bodyFormData.append("number", this.number);
      var that = this;
      this.showloading = true;
      axios({
        method: "post",
        url: "/Api/Store/get_tables",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.datelist = rt.data.datelist;
              that.build_date_options();
              that.rdata = rt.data;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          that.$nextTick(() => {
            that.showloading = false;
          });
        })
        .catch(function (error) {
          ;// console.log(error);
          that.showloading = false;
        });
    },
    submit_form() {
      if (this.name.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Name is Require";
        return;
      }
      if (this.phone.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Phone Number is Require";
        return;
      }
      if (this.number.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Number of Party is Require";
        return;
      }
      if (this.slsdate == "") {
        this.showDismissibleAlert = true;
        this.alert_msg = "Date is Require";
        return;
      }
      if (this.slstime == "") {
        this.showDismissibleAlert = true;
        this.alert_msg = "Time is Require";
        return;
      }
      if (this.verifycode.length == 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Verify Code is Require";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("name", this.name);
      bodyFormData.append("phone", this.phone);
      bodyFormData.append("number", this.number);
      bodyFormData.append("tm", this.slsdate);
      bodyFormData.append("rid", this.slstime.rid);
      bodyFormData.append("tname", this.slstime.name);
      bodyFormData.append("verifycode", this.verifycode);

      var that = this;
      this.showloading = true;
      axios({
        method: "post",
        url: "/Api/Store/hold",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.rnumber = rt.data.rnumber;
              that.rtm = rt.data.tm;
              that.name = rt.data.name;
              that.number = rt.data.number;
              that.phone = rt.data.phone;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          that.showloading = false;
        })
        .catch(function (error) {
          ;// console.log(error);
          that.showloading = false;
        });
    },
    init() {
      let store = this.$route.query.s;
      let k = this.$route.query.k;
      this.resizeBody();
      if (store == undefined) {
        this.show_error = 1;
        this.errorModel = true;
        return;
      }

      let user_id = getStore("user_id");

      var bodyFormData = new FormData();
      bodyFormData.append("store", store);
      bodyFormData.append("key", k);
      bodyFormData.append("user_id", user_id);

      var that = this;
      this.showloading = true;
      axios({
        method: "post",
        url: "/Api/Store",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.token = rt.data.token;
              that.user_id = rt.data.user_id;
              that.verifyimgdata = rt.data.verifyimg
              setStore("token", rt.data.token);
              setStore("user_id", rt.data.user_id);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.showloading = false;
          ;// console.log(error);
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.verifyimg {
  height: 2rem;
}
.language {
  width: 5rem;
}
</style>
