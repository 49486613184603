<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row>
        <b-col>
          <b-row ref="titlerow" class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
          <b-row
            ref="bodyrow"
            :style="bodyChangeHeight"
            class="justify-content-center"
          >
            <b-col>
              <b-alert
                variant="danger"
                fade
                :show="showDismissibleAlert"
                @dismissed="showDismissibleAlert = false"
                dismissible
              >
                {{ alert_msg }}
              </b-alert>
              <div ref="tabswidth">
                <b-tabs content-class="mt-3" v-model="tabIndex">
                  <b-tab :title="$t('Queue')" lazy active>
                    <el-table :data="newlist" style="width: 100%" stripe fit size="mini" >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')">
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')">
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="25"
                        :label="$t('Telephone')">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')">
                      </el-table-column>
                      <el-table-column
                        :min-width="10"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'call')"
                            size="small">
                            {{ $t('Call') }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :min-width="10"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'cancel')"
                            size="small">
                            {{ $t('Cancel') }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :min-width="10"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'pass')"
                            size="small">
                            {{ $t('Pass') }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>
                  <b-tab :title="$t('Call')" lazy :disabled="calllist.length==0" >
                    <el-table :data="calllist" style="width: 100%" stripe fit size="mini" >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')">
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')">
                      </el-table-column>
                      <el-table-column
                        prop="table_name"
                        :min-width="10"
                        :label="$t('Table')">
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="20"
                        :label="$t('Telephone')">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="20"
                        :label="$t('Name')">
                      </el-table-column>
                      <el-table-column
                        :min-width="15"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'cancel')"
                            size="small">
                            {{ $t('Cancel') }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :min-width="15"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'done')"
                            size="small">
                            {{ $t('Done') }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>
                  <b-tab :title="$t('Pass')" lazy :disabled="passlist.length==0">
                    <el-table :data="passlist" style="width: 100%" stripe fit size="mini" >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')">
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')">
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="25"
                        :label="$t('Telephone')">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')">
                      </el-table-column>
                      <el-table-column
                        :min-width="15"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'call')"
                            size="small">
                            {{ $t('Call') }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :min-width="15"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'done')"
                            size="small">
                            {{ $t('Done') }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>

                  <b-tab :title="$t('Table')" lazy>
                    <div class="tablesdiv">
                      <div>
                        <el-radio v-model="cur_floor_idx" v-for="(floor, idx) in floorlist" :key="idx" :label="idx">{{ floor.name }}</el-radio>
                        <el-button type="primary" size="small" class="mr-3" style="float: right;" @click="table_reset_color">{{ $t('Reset') }}</el-button>
                      </div>
                      <div ref="tableImage" :style="manage_table_style">
                        <div v-for="(tb, idx) in tablelist" :key="idx" :style="table_style(tb)" @click="table_change_color(tb)">{{ tb.name }}</div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab :title="$t('Reserve')" lazy>
                    <div class="mb-2">
                      <el-button
                        type="primary"
                        v-for="dt in weeklist" :key="dt"
                        @click.native.prevent="get_week_reservation(dt)"
                        size="small">
                        {{ dt }}
                      </el-button>
                    </div>
                    <el-table :data="reservelist" style="width: 100%" stripe fit size="mini" >
                      <el-table-column
                        prop="tm"
                        :min-width="15"
                        :label="$t('Time')">
                      </el-table-column>
                      <el-table-column
                        prop="number"
                        :min-width="7"
                        :label="$t('Party')">
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        :min-width="7"
                        :label="$t('Status')">
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="25"
                        :label="$t('Telephone')">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')">
                      </el-table-column>
                      <el-table-column
                        :min-width="10"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            :disabled="row.status!='New'"
                            @click.native.prevent="make_reserve_active(row, 'cancel')"
                            size="small">
                            {{ $t('Cancel') }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :min-width="10"
                        label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            :disabled="row.status!='New'"
                            @click.native.prevent="make_reserve_active(row, 'done')"
                            size="small">
                            {{ $t('Done') }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import BaseButton from "../../components/BaseButton.vue";
import {Table,TableColumn,Radio,RadioGroup,RadioButton,Button} from "element-ui";
import VueRecaptcha from "vue-recaptcha";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    BaseButton,
    VueRecaptcha,
  },
  data() {
    return {
      showDismissibleAlert: false,
      alert_msg: "test msg",
      windowHeight: 100,
      windowWidth: 100,
      h_table_area: 1024,
      y_table_area: 640,
      bodyChangeHeight: {
        height: "500px",
        maxHeight: "500px",
        overflow: "auto"
      },

      timer: false,

      cur_floor_idx: 0,
      cur_queue_id: 0,

      store: false,
      newlist: [],
      calllist: [],
      passlist: [],
      floorlist: [],
      reservelist: [],
      week_day: false,
      weeklist: [],

      tabIndex: 0,
      lang: "en",
      user_id: 0,
      token: "",
    };
  },
  computed: {
    tablelist() {
      if (this.floorlist[this.cur_floor_idx] == undefined) {
        return [];
      }
      return this.floorlist[this.cur_floor_idx].tables;
    },
    manage_table_style() {
      let w = this.w_table_area + 'px';
      let h = this.h_table_area + 'px';
      if ((this.floorlist.length <= 0) || (this.floorlist[this.cur_floor_idx]==undefined) || this.floorlist[this.cur_floor_idx].image == '' || !this.floorlist[this.cur_floor_idx].use_image) {
        return {'position': 'relative', 'width': w, 'height': h, 'border': 'solid 1px #12cdef', 'border-radius': '10px', 'background-color': '#82cdef'};
      } else {
        var imgurl = axios.defaults.baseURL + '/Api' + this.floorlist[this.cur_floor_idx].image;
        return {'position': 'relative', 'width': w, 'height': h, 'backgroundImage': `url(${imgurl})`, 'backgroundSize': this.w_table_area + 'px ' + this.h_table_area + 'px', 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'center center'};
      }
    },
  },
  mounted() {
    // console.log("mounted", this.windowHeight, window.innerHeight);
    this.lang = getStore("lang");
    if (this.lang != "cn") {
      this.lang = "en";
    } else {
      this.lang = "cn";
    }
    this.$i18n.locale = this.lang;
    this.user_id = getStore("user_id");
    this.token = getStore("token");
    
    this.week_day = false;
    this.weeklist = [];
    var dt = new Date();
    var y,m,d, fdt;
    var tm = dt.getTime();
    for (var i = 0; i < 7; i++) {
      y = '' + dt.getFullYear();
      m = '' + (dt.getMonth() + 1);
      d = '' + dt.getDate();
      if (m.length < 2) m = '0' + m;
      if (d.length < 2) d = '0' + d;
      fdt = y + '-' + m + '-' + d;
      if (!this.week_day) this.week_day = fdt;
      this.weeklist.push(fdt);
      tm += 86400000;
      dt.setTime(tm);
    }

    this.resizeBody();
    this.get_info();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    table_reset_color () {
      var bkcolor = '#c0c0c0';

      if (this.floorlist[this.cur_floor_idx] == undefined) {
        return ;
      }
      for (var i=0; i<this.floorlist[this.cur_floor_idx].tables.length; i++) {
        this.floorlist[this.cur_floor_idx].tables.bkcolor = bkcolor;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("floor_id", this.floorlist[this.cur_floor_idx].id);
      bodyFormData.append("bkcolor", bkcolor);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/table_color_all",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              ;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    table_change_color(tb) {
      if (tb.bkcolor == '#c0c0c0') {
        tb.bkcolor = '#f08080';
      } else {
        tb.bkcolor = '#c0c0c0';
      }

      var backtab = -1;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", tb.id);
      if (this.cur_queue_id > 0) {
        bodyFormData.append("queue_id", this.cur_queue_id);
        tb.bkcolor = '#f08080';
        backtab = 0;
      }
      bodyFormData.append("bkcolor", tb.bkcolor);
      this.cur_queue_id = 0;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/table_color",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (backtab >= 0) {
                that.tabIndex = backtab;
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    table_style(tb) {
      let left = parseInt(tb.offset_x * this.w_table_area / 1024);
      let top = parseInt(tb.offset_y * this.w_table_area / 1024);
      let w = 80;
      let h = 60;
      let radius = '5px';
      let ptop = 12;

      if (tb.shape == 'Square') {
        w = 60;
        h = 60;
      } else if (tb.shape == 'Round') {
        w = 70;
        h = 70;
        radius = '50%';
        ptop = 16;
      } else if (tb.shape == 'H-Rectangle') {
        w = 60;
        h = 80;
        ptop = 24;
      } else { // W-Rectangle
        ;
      }
      return {
        'position': 'absolute', 
        'background-color': tb.bkcolor, 
        'text-align': 'center',
        'font-size': '20px',
        'border': 'solid 1px #000000',
        'border-radius': radius,
        'top': top + 'px',
        'left': left + 'px',
        'width': w + 'px',
        'height': h + 'px',
        'padding-top': ptop + 'px',
        };
    },
    floor_change() {
      let tables = this.floorlist[this.cur_floor_idx].tables;
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      this.w_table_area = this.$refs.tabswidth.clientWidth;
      this.h_table_area = parseInt(640 * this.w_table_area / 1024);

      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
      this.bodyChangeHeight.maxHeight = h;
    },
    make_reserve_active(queue, act) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", queue.id);
      bodyFormData.append("act", act);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/reserve_act",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              ;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    make_active(queue, act) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", queue.id);
      bodyFormData.append("act", act);
      if (act == 'call') {
        this.cur_queue_id = queue.id;
        this.tabIndex = 3; // Table manage tab
        return;
      } else {
        this.cur_queue_id = 0;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/queue_act",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              ;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_week_reservation(dt) {
      this.week_day = dt;
      this.get_info();
    },
    get_info() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("dt", this.week_day);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/data_info",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.newlist = rt.data.new;
              that.calllist = rt.data.call;
              that.passlist = rt.data.pass;
              that.floorlist = rt.data.floor;
              that.reservelist = rt.data.reserve;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.timer = setTimeout(that.get_info, 30000);
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.active_button {
  float: right;
}
.language {
  width: 5rem;
}
body {
  background-color: #11cdef;
}
</style>
