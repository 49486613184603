<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row ref="titlerow" :style="bodyChangeHeight">
        <b-col cols="2">
          <div>
            <img class="tvlogosize" src="img/brand/logo.jpg" />
          </div>
          <div v-if="calllist.length>0">
            <div class="tvcall_title">
              {{ $t('Call') }}
            </div>
            <div v-for="(q, idx) in calllist" :key="idx" class="tvcall_div">
              {{ q.num }}
            </div>
          </div>
          <div v-if="newlist.length>0">
            <div class="tvnew_title">
              {{ $t('Queue') }}
            </div>
            <div v-for="(q, idx) in newlist" :key="idx" class="tvnew_div">
              {{ q.num }}
            </div>
          </div>
        </b-col>
        <b-col>
          <div :style="backimage"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import BaseButton from "../../components/BaseButton.vue";

const axios = require("axios");

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      windowHeight: 100,
      bodyChangeHeight: {
        height: "500px",
        maxHeight: "500px",
        overflow: "auto"
      },

      timer: "",

      store: false,
      newlist: [],
      calllist: [],
      passlist: [],

      lang: "en",
      user_id: 0,
      token: "",
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    this.lang = getStore("lang");
    if (this.lang != "cn") {
      this.lang = "en";
    } else {
      this.lang = "cn";
    }
    this.$i18n.locale = this.lang;
    this.user_id = getStore("user_id");
    this.token = getStore("token");
    this.resizeBody();
    this.get_info();
  },
  computed: {
    backimage() {
      if (!this.store || this.store.ad_img == '' || this.store.ad_img == undefined) {
        return {};
      } else {
        var imgurl = axios.defaults.baseURL + '/Api' + this.store.ad_img;
        return {'backgroundImage': `url(${imgurl})`, 'height': '100%', 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'center center', 'backgroundSize': 'cover' };
      }
    },
  },
  methods: {
    stop_loading() {
      clearTimeout(this.timer);
    },
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight) + "px";
      this.bodyChangeHeight.height = h;
      this.bodyChangeHeight.maxHeight = h;
    },
    get_info() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/data_info",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.newlist = rt.data.new;
              that.calllist = rt.data.call;
              that.passlist = rt.data.pass;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.timer = setTimeout(that.get_info, 60000);
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
  },
};
</script>
<style>
.tvlogosize {
  padding: 1rem 0;
  width: 12rem;
}
.mainbody {
  background-color: #11cdef;
}
.tvcall_title {
  margin: auto;
  width: 100%;
  color: blue;
  border: 3px solid red;
  text-align: center;
  font-weight: bold;
  font-size: 3.5rem;
}
.tvnew_title {
  margin: auto;
  width: 100%;
  border: 3px solid goldenrod;
  text-align: center;
  font-weight: bold;
  font-size: 3.5rem;
}
.tvcall_div {
  text-align: center;
  font-weight: bold;
  color: blue;
  font-size: 3.5rem;
}
.tvnew_div {
  text-align: center;
  font-weight: bold;
  font-size: 3.5rem;
}
</style>
